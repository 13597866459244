import * as React from "react";
import { IconProps } from "..";

const Linkedin = React.forwardRef<SVGSVGElement, IconProps>(
  ({ title = "linkedin", titleId = "linkedin", ...props }, svgRef) => {
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={svgRef}
        aria-labelledby={titleId}
        {...props}
      >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
          d="M19.875 3.375H4.125a.75.75 0 00-.75.75v15.75c0 .414.336.75.75.75h15.75a.75.75 0 00.75-.75V4.125a.75.75 0 00-.75-.75zM11.25 10.5v6M8.25 10.5v6"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.25 13.125a2.625 2.625 0 015.25 0V16.5"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.25 8.438a.937.937 0 100-1.875.937.937 0 000 1.875z"
          fill="currentColor"
        />
      </svg>
    );
  }
);
export default Linkedin;
